import React, { Suspense } from 'react';
import { CabinetLayout, Layout, PageLayout } from '../components';
import { graphql } from 'gatsby';

const ContactsPage = ({ location }) => {
  return (
    // <Suspense fallback="loading">
    <Layout location={location}>
      <PageLayout location={location}>
        <CabinetLayout />
      </PageLayout>
    </Layout>
    // </Suspense>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ContactsPage;
